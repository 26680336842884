import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import styles from "./styles.module.scss";

// Icons
import { ReactComponent as BackIcon } from "assets/svg/BackPrimary.svg";
import { ReactComponent as DotsIcon } from "assets/svg/dots.svg";
import { ReactComponent as CardViewIcon } from "assets/svg/cardView.svg";
import { ReactComponent as ListViewIcon } from "assets/svg/listview.svg";
import { DownOutlined } from "@ant-design/icons";
import { ReactComponent as GoalIcon } from "assets/svg/goal.svg";
import { ReactComponent as ConnectIcon } from "assets/svg/connect.svg";
import { ReactComponent as Cross } from "assets/svg/closeBox.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as CollapseIcon } from "assets/svg/collapse.svg";
import { ReactComponent as FilterIcon } from "assets/svg/filter.svg";
import { ReactComponent as Close } from "assets/svg/close.svg";
import { ReactComponent as ArrowDown } from "assets/svg/arrowDownGray.svg";
import { ReactComponent as AddIcon } from "assets/svg/plusBlue.svg";
import { ReactComponent as TrashFill } from "assets/svg/trashfill.svg";

import { ReactComponent as CompleteIcon } from "../ActionItems/Category/complete.svg";
import { ReactComponent as OnHoldIcon } from "../ActionItems/Category/onHold.svg";
import { ReactComponent as TodoIcon } from "../ActionItems/Category/todo.svg";
import { ReactComponent as InProgessIcon } from "../ActionItems/Category/inProgress.svg";
import { ReactComponent as CancelIcon } from "../ActionItems/Category/canceled.svg";

import { ReactComponent as RenameIcon } from "assets/svg/rename.svg";
import { ReactComponent as ArchiveIcon } from "assets/svg/archive.svg";
import { ReactComponent as TrashICon } from "assets/svg/trashSmall.svg";
import { ReactComponent as ShareArrow } from "assets/svg/shareArrow.svg";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { updatePlan } from "../../../modules/actions/PlanActions";

import { durationList } from "constants";

// Antd
import { DatePicker, Dropdown, Modal, Popover, Select } from "antd";

// Moment
import moment from "moment";

// Components
import RoundedProgress from "../PlanHeader/components/RoundProgress";

// React Number Format
import { NumericFormat } from "react-number-format";

// Lodash
import debounce from "lodash.debounce";

// Components
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import { getPath } from "../../../components/AppSwitcher";
import { ActionItemsModal } from "../../../components/ActionItemsModal";
import GoalSearch from "../../../components/GoalSearch";
import {
  actionItemfilterOptions,
  actionItemWeightOptions,
} from "../../ProjectPlanner/components/GoalSettings/data";

const PlanNewHeader = ({
  isShared,
  handleUpdate,
  handleChange,
  handleArchiveModal,
  handleRemoveModal,
  handleUpdateGoal,
  setIsExpandAllNotes,
  isExpandAllNotes,
  isListView,
  setIsListView,
  filterBy,
  setFilterBy,
}) => {
  const navigate = useNavigate();

  const { id } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const goalRef = useRef(null);
  const { gpsURL } = getPath();

  const $objectiveRef = useRef(null);
  const $purposeRef = useRef(null);
  const $saveRef = useRef(null);
  const $buttonsRef = useRef([]);

  const [search, setSearch] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [isNewFilter, setIsNewFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [activeTextTab, setActiveTextTab] = useState(1);
  const [textEdit, setTextEdit] = useState(false);

  const [selectedGoal, setSelectedGoal] = useState(null);
  const [isGoalSelect, setIsGoalSelect] = useState(false);
  const [isGoalModel, setIsGoalModel] = useState(false);
  const [goalName, setGoalName] = useState("");

  const [start, setStartCopy] = useState("");
  const [current, setCurrentCopy] = useState("");
  const [target, setTargetCopy] = useState("");
  const [placeholderStart, setPlaceholderStart] = useState("0.00");
  const [placeholderCurrent, setPlaceholderCurrent] = useState("0.00");
  const [placeholderTarget, setPlaceholderTarget] = useState("0.00");

  const [objectiveCopy, setObjectiveCopy] = useState("");
  const [purposeCopy, setPurposeCopy] = useState("");
  const [searchGoal, setSearchGoal] = useState("");

  const [filters, setFilters] = useState({
    status: null,
    weight: null,
    progress: null,
    startDate: null,
    dueDate: null,
    weeklyDuration: null,
    assignedTo: null,
  });

  const { user } = useSelector((state) => state.auth);

  const userId =
    user?.data?.external_accounts?.find(
      (account) => account.decision_and_project
    )?.decision_and_project ?? null;

  const { planDetails, allGoalList, selectedGoalList, loadingUpdatePlan } =
    useSelector((state) => state.plan.plan);
  const title = planDetails?.name;

  const { users: planUsers } = useSelector((state) => state.user.projectOwners);

  const AssignOptions = planUsers?.map((user) => ({
    label: user.email,
    value: user.id,
    initials: user.name
      ? user.name.match(/\b\w/g).join("")
      : user.email[0].toUpperCase(),
  }));

  const selectedAssignee = planUsers?.find(
    (option) => option.email === filters?.assignedTo?.label
  );

  const userName =
    selectedAssignee?.first_name && selectedAssignee?.last_name
      ? selectedAssignee?.first_name.substring(0, 1) +
        selectedAssignee?.last_name.substring(0, 1)
      : selectedAssignee?.name
      ? selectedAssignee?.name.split(" ").length > 1
        ? selectedAssignee.name.split(" ")[0].substring(0, 1) +
          selectedAssignee.name.split(" ")[1].substring(0, 1)
        : selectedAssignee.name.substring(0, 2)
      : selectedAssignee?.email?.substring(0, 2);

  const isTrueOwner = user?.email === planDetails?.invited_owner;
  const onwer = planDetails?.owner == userId;

  useEffect(() => {
    setObjectiveCopy(planDetails?.objective);
    setPurposeCopy(planDetails?.purpose);
  }, [planDetails?.objective, planDetails?.purpose]);

  const debouncedHandleUpdateGoal = useCallback(
    debounce((field, value) => {
      handleUpdateGoal(field, value);
    }, 500),
    []
  );

  useEffect(() => {
    setStartCopy(planDetails?.goal?.start?.toString() ?? "");
    setCurrentCopy(planDetails?.goal?.current?.toString() ?? "");
    setTargetCopy(planDetails?.goal?.target?.toString() ?? "");
  }, [
    planDetails?.goal?.start,
    planDetails?.goal?.current,
    planDetails?.goal?.target,
  ]);

  const onChangeHandle = (field, value) => {
    if (field === "start") setStartCopy(value);
    else if (field === "current") setCurrentCopy(value);
    else if (field === "target") setTargetCopy(value);

    if (value && !isNaN(value)) {
      debouncedHandleUpdateGoal(field, value);
    } else {
      debouncedHandleUpdateGoal(field, null);
    }
  };

  const disabledGoalInput = isShared || planDetails?.goal?.label === null;

  const onObjectiveBlurHandle = (evt) => {
    if (isShared) return;

    handleUpdate("objective", objectiveCopy);

    if (evt.relatedTarget !== $saveRef.current) {
      setTextEdit(false);
    }
  };

  const onPurposeBlurHandle = (evt) => {
    if (isShared) return;
    handleUpdate("purpose", purposeCopy);

    if (evt.relatedTarget !== $saveRef.current) {
      setTextEdit(false);
    }
  };

  const getMenuProps = (item) => ({
    className: "removeOption",
    items: [
      {
        key: "1",
        onClick: () => {
          handleChange("renamePlanModal", true);
          handleChange("planName", item?.name);
        },
        label: (
          <span className={styles.menuItem}>
            <RenameIcon /> Rename
          </span>
        ),
      },
      {
        key: "2",
        onClick: handleArchiveModal,
        label: (
          <span className={styles.menuItem}>
            <ArchiveIcon />
            Archive Plan
          </span>
        ),
      },
      {
        key: "3",
        onClick: handleShare,
        disabled: item?.is_archived,
        label: (
          <span className={styles.menuItem}>
            <ShareArrow />
            Share
          </span>
        ),
      },
      {
        key: "4",
        onClick: handleRemoveModal,
        label: (
          <span className={styles.deleteItem}>
            <TrashICon />
            Delete Project
          </span>
        ),
      },
    ],
  });

  const handleShare = () => {
    navigate(`/project-planner/plan-details/${planDetails?.id}/resources`);
  };

  const handleCopyChange = (event, setValue, maxLength = 256) => {
    let input = event.target.value;

    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }

    setValue(input);
  };

  const navTabs = [
    {
      id: "1",
      visible: true,
      text: "Action Items",
      to: `/project-planner/plan-details/${planDetails?.id}`,
    },
    {
      id: "2",
      visible: !isShared,
      text: "Project Plan Details",
      to: `/project-planner/plan-details/${planDetails?.id}/details`,
    },
    {
      id: "3",
      visible: !isShared,
      text: "Resources",
      to: `/project-planner/plan-details/${planDetails?.id}/resources`,
    },
    {
      id: "4",
      visible: !isShared,
      text: "Project Insights",
      to: `/project-planner/plan-details/${planDetails?.id}/insights`,
    },
  ];

  // Handle click outside
  const handleClickOutside = (event) => {
    if (
      !$objectiveRef.current?.contains(event.target) &&
      !$purposeRef.current?.contains(event.target) &&
      !$buttonsRef.current?.some((ref) => ref.contains(event.target))
    ) {
      setTextEdit(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (goalRef.current && !goalRef.current.contains(event.target)) {
        setIsGoalSelect(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [goalRef]);

  useEffect(() => {
    if (selectedGoalList && planDetails?.goal_public_id) {
      setSelectedGoal({
        id: selectedGoalList?.id,
        goal_name: selectedGoalList?.goal_name,
        type: selectedGoalList?.goal?.type,
      });
    }
  }, [selectedGoalList]);

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
  };

  const handleClose = () => {
    setIsGoalModel(false);
    setGoalName("");
    setSelectedGoal({
      id: selectedGoalList?.id,
      goal_name: selectedGoalList?.goal_name,
      type: selectedGoalList?.goal?.type,
    });
  };

  const handleConnect = () => {
    const rest = {
      goal_public_id: selectedGoal?.id || "null",
    };
    dispatch(updatePlan(id, rest, handleClose, true));
  };

  const filterGoalList = allGoalList?.filter((goal) =>
    goal?.goal_name.toLowerCase().includes(searchGoal.toLowerCase())
  );

  const handleCollageNotes = () => {
    setIsExpandAllNotes(!isExpandAllNotes);
  };

  const removeFilterOption = actionItemfilterOptions.filter(
    (option) => !selectedFilters.some((filter) => filter.value === option.value)
  );

  const StatusOptions = [
    {
      label: "To Do",
      value: 1,
      icon: <TodoIcon />,
    },
    {
      label: "In Progress",
      value: 2,
      icon: <InProgessIcon />,
    },
    {
      label: "On Hold",
      value: 3,
      icon: <OnHoldIcon />,
    },
    {
      label: "Completed",
      value: 4,
      icon: <CompleteIcon />,
    },
    {
      label: "Cancelled",
      value: 5,
      icon: <CancelIcon />,
    },
  ];

  const handleApply = () => {
    setFilterBy(filters);
    setIsFilter(false);
  };

  const mapFiltersToOptions = (filters) => {
    return Object.keys(filters)
      .filter((key) => filters[key] !== null)
      .map((key) => {
        const option = actionItemfilterOptions.find((opt) => opt.value === key);
        return option ? { ...option, selectedValue: filters[key] } : null;
      })
      .filter(Boolean);
  };

  const handleResetFilter = () => {
    setIsFilter(false);
    setSelectedFilters([]);
    setFilters({
      status: null,
      weight: null,
      progress: null,
      startDate: null,
      dueDate: null,
      weeklyDuration: null,
      assignedTo: null,
    });
  };

  return (
    <>
      <Modal
        footer={null}
        closable={false}
        title={false}
        open={isGoalModel}
        centered
        width={520}
        className="disablePadding"
      >
        <div className={styles.connectModel}>
          <div className={styles.modelHeader}>
            <h3>Connect Goal</h3>
            <Cross className={"c-pointer"} onClick={handleClose} />
          </div>

          <div className={styles.main}>
            <h4 className={styles.sureText}>
              Are you sure you want to connect the following goal with your
              project?
            </h4>

            <div className={styles.goal_content}>
              <label>Goal Name</label>

              <h4>
                {goalName || selectedGoal?.goal_name || "No Goal Connected"}
              </h4>
            </div>
          </div>

          <div className={styles.actions}>
            <button onClick={handleClose}>Cancel</button>
            <button onClick={handleConnect} disabled={loadingUpdatePlan}>
              {loadingUpdatePlan ? <LoadingOutlined /> : "Connect"}
            </button>
          </div>
        </div>
      </Modal>

      <div className={styles.planHeader}>
        <div className={styles.planHeader_title}>
          <div className={styles.planHeader_title_text}>
            {!isShared && (
              <Link className={styles.back} to="/project-planner/">
                <BackIcon
                  style={{
                    cursor: "pointer",
                    marginTop: "-2px",
                  }}
                />
              </Link>
            )}

            <h3>PROJECT</h3>
          </div>
          <div className={styles.planHeader_items}>
            {/* <div className={styles.action}>
            <DownloadIcon />
            EXPORT
          </div> */}

            {/* <Link
            className={styles.action}
            to={`/project-planner/plan-details/${planDetails?.id}/resources`}
          >
            <ShareGray />
            SHARE
          </Link> */}
          </div>
        </div>

        <div className={styles.planHeader_details}>
          <div className={styles.planHeader_details_left}>
            <div className={styles.title}>
              <h2 className="text-oneline">{title}</h2>

              {!isShared && (
                <Dropdown menu={getMenuProps(planDetails)} trigger={["click"]}>
                  <button type="button" className={styles.settings}>
                    <DotsIcon />
                  </button>
                </Dropdown>
              )}
            </div>

            <h6 className={styles.deadline}>
              Deadline:
              <span>
                <span>
                  {moment(planDetails?.dead_line).format("MM/DD/YYYY")}
                </span>
              </span>
            </h6>

            <div className={styles.objectiveButtons}>
              <button
                ref={(el) => ($buttonsRef.current[0] = el)}
                className={activeTextTab === 1 ? styles.active : ""}
                type="button"
                onClick={() => {
                  setActiveTextTab(1);
                  if (!isShared) {
                    setTextEdit(true);
                  }
                }}
              >
                Objective
              </button>
              <button
                ref={(el) => ($buttonsRef.current[1] = el)}
                className={activeTextTab === 2 ? styles.active : ""}
                type="button"
                onClick={() => {
                  setActiveTextTab(2);
                  if (!isShared) {
                    setTextEdit(true);
                  }
                }}
              >
                Purpose
              </button>
            </div>

            <div className={styles.objectiveTextarea}>
              {activeTextTab === 1 && (
                <textarea
                  ref={$objectiveRef}
                  value={objectiveCopy || ""}
                  onChange={(event) =>
                    handleCopyChange(event, setObjectiveCopy, 255)
                  }
                  onBlur={onObjectiveBlurHandle}
                  name={"name"}
                  readOnly={!textEdit}
                />
              )}

              {activeTextTab === 2 && (
                <textarea
                  ref={$purposeRef}
                  value={purposeCopy || ""}
                  onChange={(event) =>
                    handleCopyChange(event, setPurposeCopy, 255)
                  }
                  onBlur={onPurposeBlurHandle}
                  name={"purpose"}
                  readOnly={!textEdit}
                />
              )}
            </div>
          </div>
          <div className={styles.planHeader_details_right}>
            <div className={styles.goalitem}>
              <div className={styles.goalitem_content}>
                {selectedGoalList?.goal_name ? (
                  <h4 className="text-oneline">
                    {selectedGoalList?.goal_name}
                  </h4>
                ) : (
                  <h2>No Goal Connected</h2>
                )}

                <div ref={goalRef} className={styles.goal_input_wrapper}>
                  <div>
                    {selectedGoalList?.goal_name ? (
                      <h6
                        onClick={() => {
                          onwer &&
                            window.open(
                              `https://${gpsURL}/journeys/action-plans?j=${selectedGoalList?.journey}&g=${selectedGoalList?.id}&m=${selectedGoalList?.master_plan_id}/`,
                              "_blank"
                            );
                        }}
                      >
                        <GoalIcon />
                        See Goal
                      </h6>
                    ) : (
                      <h6
                        onClick={() => {
                          onwer && setIsGoalSelect(!isGoalSelect);
                        }}
                      >
                        <ConnectIcon />
                        Connect Goal
                      </h6>
                    )}
                  </div>

                  <GoalSearch
                    goalRef={goalRef}
                    allGoalList={allGoalList}
                    isGoalSelect={isGoalSelect}
                    setIsGoalSelect={setIsGoalSelect}
                    setSelectedGoal={setSelectedGoal}
                    setGoalName={setGoalName}
                    setIsGoalModel={setIsGoalModel}
                    onwer={onwer}
                    isHeader={true}
                  />
                </div>
              </div>
              <div className={styles.goalitem_metric}>
                <div className={styles.goalitem_select}>
                  <label>Metric</label>

                  <CustomDropdown
                    handleUpdateGoal={handleUpdateGoal}
                    planDetails={planDetails}
                    disabled={isShared}
                    handleChange={handleChange}
                  />
                </div>

                <div className={styles.goalitem_input}>
                  <label>Start</label>
                  <NumericFormat
                    name="start"
                    value={start === "0.00" ? "" : start}
                    decimalScale={2}
                    fixedDecimalScale
                    disabled={disabledGoalInput}
                    onChange={(evt) =>
                      onChangeHandle("start", evt.target.value)
                    }
                    onFocus={() => setPlaceholderStart("")}
                    onBlur={() => setPlaceholderStart("0.00")}
                    placeholder={placeholderStart}
                    className={styles.goalitem_metric_input}
                  />
                </div>
                <div className={styles.goalitem_input}>
                  <label>Current</label>
                  <NumericFormat
                    name="current"
                    value={current === "0.00" ? "" : current}
                    decimalScale={2}
                    fixedDecimalScale
                    disabled={disabledGoalInput}
                    onChange={(evt) =>
                      onChangeHandle("current", evt.target.value)
                    }
                    onFocus={() => setPlaceholderCurrent("")}
                    onBlur={() => setPlaceholderCurrent("0.00")}
                    placeholder={placeholderCurrent}
                    className={styles.goalitem_metric_input}
                  />
                </div>
                <div className={styles.goalitem_input}>
                  <label>Target</label>
                  <NumericFormat
                    name="target"
                    value={target === "0.00" ? "" : target}
                    decimalScale={2}
                    fixedDecimalScale
                    allowemptyformatting="true"
                    disabled={disabledGoalInput}
                    onChange={(evt) =>
                      onChangeHandle("target", evt.target.value)
                    }
                    onFocus={() => setPlaceholderTarget("")}
                    onBlur={() => setPlaceholderTarget("0.00")}
                    placeholder={placeholderTarget}
                    className={styles.goalitem_metric_input}
                  />
                </div>
              </div>
            </div>

            <div className={styles.progress}>
              <RoundedProgress
                size={100}
                progress={(
                  Number(planDetails?.goal?.progress || 0) * 100
                ).toFixed(0)}
                label={"Goal Progress"}
                strokeWidth={7}
                innerStrokeWidth={7}
                circleTwoStroke="#FF9900"
                circleOneStroke="#E5E5E5"
                fill="transparent"
              />

              <RoundedProgress
                size={100}
                progress={(
                  Number(planDetails?.plan_progress || 0) * 100
                ).toFixed(0)}
                label={"Plan Progress"}
                strokeWidth={7}
                innerStrokeWidth={7}
                circleTwoStroke="#89C740"
                circleOneStroke="#E5E5E5"
                fill="transparent"
              />
            </div>
          </div>
        </div>

        <div className={styles.planHeader_actions}>
          <div className={styles.navWrapper}>
            {navTabs
              .filter((tab) => tab.visible)
              .map((tab) => (
                <Link
                  key={tab.id}
                  to={tab.to}
                  className={`${isShared ? styles.disabled : ""} ${
                    pathname.replace(/\/$/, "") === tab.to.replace(/\/$/, "") ||
                    isShared
                      ? styles.active
                      : ""
                  }`}
                >
                  {tab.text}
                </Link>
              ))}
          </div>

          <div className={styles.planHeader_options}>
            <Dropdown
              overlayClassName="listAndCardView_dropdown"
              menu={{
                items: [
                  {
                    label: "List View",
                    icon: <ListViewIcon />,
                    onClick: () => setIsListView(true),
                  },
                  {
                    label: "Card View",
                    icon: <CardViewIcon />,
                    onClick: () => setIsListView(false),
                  },
                ],
              }}
              trigger={["click"]}
              showArrow={true}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <span className={styles.view}>
                {isListView ? <ListViewIcon /> : <CardViewIcon />}{" "}
                {isListView ? "List View" : "Card View"}
                <DownOutlined />
              </span>
            </Dropdown>

            <Popover
              content={
                <>
                  <div className={styles.filterContainer}>
                    <div className={styles.filterHeader}>
                      <h3>Filters</h3>
                      <Close
                        className="cursor-pointer"
                        width={16}
                        height={16}
                        onClick={handleResetFilter}
                      />
                    </div>

                    <div className={styles.filterContain}>
                      {selectedFilters.map((filter, index) => (
                        <div className={styles.filterSelected_box} key={index}>
                          <div className={styles.filterSelected_header}>
                            <label htmlFor="">{filter?.label}</label>

                            <TrashFill
                              className="cursor-pointer"
                              onClick={() => {
                                setSelectedFilters(
                                  selectedFilters.filter(
                                    (selectedFilter) =>
                                      selectedFilter.value !== filter.value
                                  )
                                );

                                setFilters({
                                  ...filters,
                                  [filter.value]: null,
                                });
                              }}
                            />
                          </div>
                          {filter?.value === "status" ? (
                            <div className={styles.filterSelected_content}>
                              <Select
                                suffixIcon={<ArrowDown />}
                                className={styles.filterSelect}
                                placeholder="Select status"
                                onChange={(value) => {
                                  if (value) {
                                    setFilters({
                                      ...filters,
                                      status: value,
                                    });
                                  } else {
                                    setFilters({
                                      ...filters,
                                      status: null,
                                    });
                                  }
                                }}
                                value={filters?.status}
                              >
                                {StatusOptions.map((option, index) => (
                                  <Select.Option
                                    key={index}
                                    value={option.value}
                                  >
                                    <span
                                      style={{
                                        marginRight: "5px",
                                      }}
                                    >
                                      {option.icon}{" "}
                                    </span>
                                    {option.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </div>
                          ) : filter?.value === "startDate" ? (
                            <div className={styles.filterSelected_content}>
                              <DatePicker
                                className={styles.filterSelect}
                                placeholder="Select Start Date"
                                onChange={(date, dateString) => {
                                  if (dateString) {
                                    setFilters({
                                      ...filters,
                                      startDate: dateString,
                                    });
                                  } else {
                                    setFilters({
                                      ...filters,
                                      startDate: null,
                                    });
                                  }
                                }}
                                value={
                                  filters?.startDate
                                    ? moment(filters?.startDate)
                                    : null
                                }
                              />
                            </div>
                          ) : filter?.value === "dueDate" ? (
                            <div className={styles.filterSelected_content}>
                              <DatePicker
                                className={styles.filterSelect}
                                placeholder="Select Due Date"
                                onChange={(date, dateString) => {
                                  if (dateString) {
                                    setFilters({
                                      ...filters,
                                      dueDate: dateString,
                                    });
                                  } else {
                                    setFilters({
                                      ...filters,
                                      dueDate: null,
                                    });
                                  }
                                }}
                                value={
                                  filters?.dueDate
                                    ? moment(filters?.dueDate)
                                    : null
                                }
                              />
                            </div>
                          ) : filter?.value === "weeklyDuration" ? (
                            <div className={styles.filterSelected_content}>
                              <Select
                                suffixIcon={<ArrowDown />}
                                className={styles.filterSelect}
                                placeholder="Select Weekly Duration"
                                onChange={(value) => {
                                  if (value) {
                                    setFilters({
                                      ...filters,
                                      weeklyDuration: value,
                                    });
                                  } else {
                                    setFilters({
                                      ...filters,
                                      weeklyDuration: null,
                                    });
                                  }
                                }}
                                value={
                                  filters?.weeklyDuration
                                    ? filters?.weeklyDuration
                                    : null
                                }
                              >
                                {durationList.map((count, index) => (
                                  <Option key={index} value={count.value}>
                                    {count.key}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          ) : filter?.value === "assignedTo" ? (
                            <div className={styles.filterSelected_content}>
                              <Select
                                suffixIcon={<ArrowDown />}
                                placeholder="Select Assignee"
                                style={{
                                  width: "100%",
                                  border: "1px solid #E5E5E5",
                                }}
                                onSelect={(value) => {
                                  if (value) {
                                    setFilters({
                                      ...filters,
                                      assignedTo: value,
                                    });
                                  } else {
                                    setFilters({
                                      ...filters,
                                      assignedTo: null,
                                    });
                                  }
                                }}
                                labelInValue
                                showSearch
                                className="antd-assignee"
                                filterOption={(input, option) =>
                                  option.children
                                    ?.toLowerCase()
                                    ?.includes(input?.toLowerCase())
                                }
                                value={userName}
                              >
                                {AssignOptions.map((option, index) => {
                                  const selectedUser =
                                    option?.label == filters?.assignedTo;

                                  return (
                                    <Select.Option
                                      key={index}
                                      value={option.value}
                                      style={{
                                        backgroundColor: selectedUser
                                          ? "#e6f7ff"
                                          : "#fff",
                                      }}
                                    >
                                      {option.label}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </div>
                          ) : filter?.value === "progress" ? (
                            <div className={styles.filterSelected_content}>
                              <Select
                                options={actionItemWeightOptions}
                                placeholder="Select Progress"
                                suffixIcon={<ArrowDown />}
                                className={styles.filterSelect}
                                onChange={(value) => {
                                  if (value !== undefined && value !== null) {
                                    setFilters({
                                      ...filters,
                                      progress: value,
                                    });
                                  } else {
                                    setFilters({
                                      ...filters,
                                      progress: null,
                                    });
                                  }
                                }}
                                value={filters?.progress}
                              />
                            </div>
                          ) : (
                            <div className={styles.filterSelected_content}>
                              <Select
                                options={actionItemWeightOptions}
                                suffixIcon={<ArrowDown />}
                                placeholder="Select Weight"
                                className={styles.filterSelect}
                                onChange={(value) => {
                                  if (value !== undefined && value !== null) {
                                    setFilters({
                                      ...filters,
                                      weight: value,
                                    });
                                  } else {
                                    setFilters({
                                      ...filters,
                                      weight: null,
                                    });
                                  }
                                }}
                                value={filters?.weight}
                              />
                            </div>
                          )}
                        </div>
                      ))}

                      {(selectedFilters.length === 0 || isNewFilter) && (
                        <div className={styles.filterSelect_box}>
                          <label htmlFor="">Select Filter</label>

                          <Select
                            options={removeFilterOption}
                            suffixIcon={<ArrowDown />}
                            className={styles.filterSelect}
                            onChange={(value) => {
                              setSelectedFilters([
                                ...selectedFilters,
                                actionItemfilterOptions.find(
                                  (option) => option.value === value
                                ),
                              ]);
                              setIsNewFilter(false);
                            }}
                          />
                        </div>
                      )}

                      {!(selectedFilters.length === 0 || isNewFilter) && (
                        <div
                          className={styles.AddFiler}
                          onClick={() => {
                            setIsNewFilter(true);
                          }}
                        >
                          <AddIcon /> Add Filter
                        </div>
                      )}
                    </div>

                    <div className={styles.actionButton}>
                      <button onClick={handleResetFilter}>Cancel</button>
                      <button onClick={handleApply}>Apply</button>
                    </div>
                  </div>
                </>
              }
              trigger="click"
              getPopupContainer={(trigger) => trigger.parentElement}
              placement="bottomRight"
              overlayClassName={"criteria_popover"}
              open={isFilter}
              onOpenChange={(value) => {
                if (value) {
                  setSelectedFilters(mapFiltersToOptions(filterBy));
                  setFilters(filterBy);
                } else {
                  setSelectedFilters([]);
                  setFilters({});
                }
                setIsFilter(value);
              }}
            >
              <div
                className={`${styles.filterButton} ${
                  isFilter ? styles.filterButtonActive : ""
                }`}
                onClick={() => {
                  setIsFilter(!isFilter);
                }}
              >
                <FilterIcon width={12} height={14} /> Filter
              </div>
            </Popover>

            <button
              className={styles.expandAllNotes}
              type="button"
              onClick={handleCollageNotes}
            >
              <CollapseIcon />
              {isExpandAllNotes ? "Collapse Notes" : "Expand Notes"}
            </button>
            {/*

          <Dropdown
            overlayClassName="listAndCardView_dropdown"
            menu={{
              items: [
                {
                  label: "List View",
                  icon: <ListViewIcon />,
                  onClick: () => setIsListView(true),
                },
                {
                  label: "Card View",
                  icon: <CardViewIcon />,
                  onClick: () => setIsListView(false),
                },
              ],
            }}
            trigger={["click"]}
            showArrow={true}
          >
            <span className={styles.view}>
              {isListView ? <ListViewIcon /> : <CardViewIcon />}{" "}
              {isListView ? "List View" : "Card View"}
              <DownOutlined />
            </span>
          </Dropdown> */}

            {/* <div className={styles.searchWrapper}>
            <SearchIcon className={styles.icon} width={20} />

            <input
              type="text"
              className={styles.search}
              placeholder={"Search projects"}
              value={search}
              onChange={handleSearch}
            />
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanNewHeader;
