export interface UnitDataItem {
  id?: string;
  label: string;
  value: string;
  items: string[];
}
import { DownOutlined } from "@ant-design/icons";

export const unitsData: UnitDataItem[] = [
  {
    label: "Weight",
    value: "weight",
    items: ["kg", "lbs"],
  },
  {
    label: "Height",
    value: "height",
    items: ["cm", "ft", "inches"],
  },
  {
    label: "Distance",
    value: "distance",
    items: ["km", "miles"],
  },
  {
    label: "Energy",
    value: "energy",
    items: ["kcal", "kj"],
  },
  {
    label: "Time",
    value: "time",
    items: ["minutes", "hours"],
  },
  {
    label: "Volume",
    value: "volume",
    items: ["cups", "fl oz", "ml"],
  },
  {
    label: "Count",
    value: "count",
    items: ["#"],
  },
  {
    label: "Scale",
    value: "scale",
    items: ["%"],
  },
  {
    label: "Currency",
    value: "currency",
    items: ["$", "£", "€"],
  },
];

export const actionItemfilterOptions = [
  {
    label: "Weight",
    value: "weight",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Progress",
    value: "progress",
  },
  {
    label: "Start Date",
    value: "startDate",
  },
  {
    label: "Due Date",
    value: "dueDate",
  },
  {
    label: "Weekly Duration",
    value: "weeklyDuration",
  },
  {
    label: "Assigned To",
    value: "assignedTo",
  },
];

export const actionItemWeightOptions = [
  {
    label: "0",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "6",
    value: 6,
  },
  {
    label: "7",
    value: 7,
  },
  {
    label: "8",
    value: 8,
  },
  {
    label: "9",
    value: 9,
  },
  {
    label: "10",
    value: 10,
  },
];
