import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { ReactComponent as EllipsisOutlined } from "./elipse.svg";
import classes from "./option.module.scss";
import { DeleteModal, UploadModal } from "scenes/DecisionMatrix";
// import {
//   updateDecision,
//   updateOption,
// } from "../../../../modules/actions/DecisionMatrixActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import cc from "classnames";
import { UpdateOption } from "redux/DecisionMatrix/UpdateOptions/action";
import { UpdateDecision } from "redux/DecisionMatrix/UpdateDecision/action";
import { Checkbox, Progress, Tooltip } from "antd";

import { ReactComponent as FlagRed } from "./flagRed.svg";
import { ReactComponent as FlagGreen } from "./flagGreen.svg";
import { ReactComponent as FlagYellow } from "./flagYellow.svg";
// import { ReactComponent as ShortlistIcon } from "./shortlist.svg";
import { ReactComponent as PPDARK } from "./pp_dark.svg";
import { ReactComponent as PPLIGHT } from "./pp_light.svg";
import { ReactComponent as Finalist } from "./Finalist.svg";
import { ReactComponent as GoldenFinalist } from "./Goldenfinalist.svg";

import { Tooltip as AntdTooltip } from "antd";
import { RemoveFromShortlist } from "redux/DecisionMatrix/RemoveFromShortlist/action";
import { AddToShortlist } from "redux/DecisionMatrix/AddToShortlist/action";
import { Dropdown, Space, Input } from "antd";
import Item from "antd/lib/list/Item";
import { RemoveFromFinalist } from "redux/DecisionMatrix/RemoveFromFinalist/action";
import { AddToFinalist } from "redux/DecisionMatrix/AddToFinalist/action";
import { getCookie } from "utils/StorageVariables";
import { useProject } from "../../../../utils/utility";
import { WinnerModal } from "../WinnerModal";

const tooltip = {
  green: {
    title: "More than 90% criteria has been scored.",
    color: "green",
  },
  yellow: {
    title:
      "70%-90% criteria has been scored and the adjusted score is +/- 30% accurate.",
    color: "yellow",
  },
  red: {
    title: "Less than 70% criteria has been scored.",
    color: "red",
  },
};

const Option = ({
  optionItem,
  decisionId,
  // hanldeCheckbox,
  i,
  // shortlistedIds,
  isShortList,
  isShared,
  // unShortlistedIds,
  second_stage_adjusted_score,
  adjusted_score,
  columnsToShow,
  ids,
  setIds,
  score_criteria_percentage,
  // setIsFinalists,
  // isFinalists,
  // criteriaId,
}) => {
  const { success: decision } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const { project } = useProject();

  const secure_token = query.get("secure_token");

  // const { success: userDecisions } = useSelector(
  //   (state) => state.getDecisionReducer
  // );

  const [option, setOption] = useState();
  const [isView, setIsView] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { TextArea } = Input;
  const textAreaRef = useRef(null);

  const highlightOption = () => {
    dispatch(
      UpdateOption(
        {
          id: option?.id,
          body: { highlighted: !option.highlighted },
        },
        decisionId
      )
    );
  };

  useEffect(() => {
    if (!optionItem) return;

    setOption({ ...optionItem });
    setIsRename({ flag: false, value: optionItem.name });
    setIsUpload(false);
  }, [
    optionItem.id,
    optionItem.image,
    optionItem.completed_criteria_percent,
    optionItem.shortlist,
    optionItem.highlighted,
    optionItem?.finalist,
  ]);

  const changeImage = () => {
    setIsUpload(true);
  };

  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isRename, setIsRename] = useState({ flag: false, value: "" });
  const [isOpenUpload, setIsUpload] = useState(false);
  const [isFocused, setIsFocused] = useState(true);
  const [isShowWinner, setIsShowWinner] = useState(false);

  const { success: userDecision } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );
  /* const showModal = (e) => {
    e.stopPropagation();
  }; */

  const selectAsWinner = () => {
    dispatch(
      UpdateDecision(
        { id: option?.decision, body: { final_option: option?.id } },
        true,
        true
      )
    );
    setIsShowWinner(true)
   
  };

  const deleteOptionClick = () => {
    setIsShowDeleteModal(true);
  };

  const rename = () => {
    if (ids.includes(optionItem?.id)) return;
    if (option?.id) {
      dispatch(
        UpdateOption({ id: option?.id, body: { name: isRename?.value } })
      );
    }
    setIsRename({ ...isRename, flag: false });
  };
  const onEditDetailsClickHandle = () => {
    navigate(
      `/${project}/${decisionId}/option/${option?.id}`
      // `/decision-manager/${decisionId}/option/${option?.id}/${criteriaId}`
    );
  };

  useEffect(() => {
    const FilterCookie = JSON.parse(getCookie("isAscending"));
    const currentFilter = FilterCookie?.find(
      (item) => item?.decId == decisionId
    );
    setIsView(currentFilter);
  }, [!isShortList]);

  useEffect(() => {
    if (textAreaRef?.current) {
      const length = textAreaRef?.current?.value?.length;
      if (length > 0) {
        textAreaRef.current.setSelectionRange(length, length);
      }
    }
  }, [isRename.value]);

  const isFinal = decision?.final_option?.id === option?.id;
  // userDecisions?.find((d) => d.id === decisionId)?.final_option?.id ===
  // option?.id;
  // const handleFocus = () => {
  //   setIsFocused(true);
  // };

  const handleFocus = (event) => {
    setIsFocused(true);
    const length = event?.target?.value?.length;
    if (length > 0) {
      event.target.setSelectionRange(length, length);
      event.target.scrollTop = event.target.scrollHeight
    }
  };

  const handleBlur = () => {
    rename();
    setIsRename({ ...isRename, flag: false });
    setIsFocused(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      rename();
      setIsRename({ ...isRename, flag: false });
    }
  };

  // const rename = () => {
  //   // Implement the logic to handle the renaming here
  //   // For example, update your data or send it to the server
  //   console.log("Renaming...");
  // };

  const handleInputChange = (e) => {
    let input = e.target.value.replaceAll("\n", "");
    if (input.replaceAll("\n", "").length > 255) {
      setIds((prevIds) => [...prevIds, optionItem?.id]);
    } else {
      setIds((prevIds) => prevIds.filter((id) => id !== optionItem?.id));
    }

    setIsRename({ ...isRename, value: input });
  };

  const items = [
    /* {
      isShow: option?.image,
      key: "1",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv"
            onClick={() => changeImage()}
          >
            CHANGE IMAGE
          </div>
        </>
      ),
    }, */
    {
      isShow: true,
      key: "2",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv "
            onClick={onEditDetailsClickHandle}
          >
            EDIT DETAILS
          </div>
        </>
      ),
    },

    {
      isShow: !isFinal && option?.adjusted_score > 0 && isRename?.value,
      key: "3",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv"
            onClick={() => selectAsWinner()}
          >
            SELECT AS WINNER
          </div>
        </>
      ),
    },

    {
      isShow: !option?.highlighted,
      key: "6",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv"
            onClick={() => highlightOption()}
          >
            HIGHLIGHT OPTION
          </div>
        </>
      ),
    },
    {
      isShow: option?.highlighted,
      key: "7",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv"
            onClick={() => highlightOption()}
          >
            REMOVE HIGHLIGHT
          </div>
        </>
      ),
    },
    {
      isShow: true,
      key: "8",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv"
            onClick={(e) => {
              deleteOptionClick();
              // e.stopPropagation();
            }}
          >
            DELETE
          </div>
        </>
      ),
    },
    {
      isShow: true,
      key: "9",
      label: (
        <>
          <div className="cursor-pointer optionDiv" onClick={handleFocus}>
            RENAME
          </div>
        </>
      ),
    },
    {
      isShow: true,
      key: "10",
      label: (
        <>
          <div
            className="cursor-pointer optionDiv"
            onClick={() => changeImage()}
          >
            CHANGE IMAGE
          </div>
        </>
      ),
    },
  ];

  const inputClassName = `${classes.input} ${isFocused ? classes.inputFocused : ""
    } ${isFinal ? classes.inputFinal : ""}`;

  return (
    <div
      className={classes.option}
    //     onClick={() => {
    //     if (!isRename.flag) {
    //         navigate(`/decision-manager/${decisionId}/option/${option.id}`)
    //     }
    // }}
    >
      <div className={classes.nameWrapper}>
        <div className={classes.checkbox}>
          {/* {!decision?.is_sample && (
            <Checkbox
              checked={
                isShortList
                  ? shortlistedIds?.includes(option?.id)
                  : unShortlistedIds?.includes(option?.id)
              }
              onChange={hanldeCheckbox}
              className="decision_table_option_checkbox"
            />
          )} */}
          <span>{i + 1}</span>
        </div>
        <div className={classes.img}>
          {option?.image ? (
            <img className={classes.img} src={option?.image} alt="" />
          ) : (
            <button
              onClick={() =>
                !decision?.is_sample &&
                !secure_token &&
                !isShared &&
                setIsUpload(true)
              }
              className={classes.img}
              disabled={decision?.is_sample || secure_token || isShared}
            >
              <img src={require("./Option avatar.png")} alt="" />
            </button>

            // <UploadImage option={option} decisionId={decisionId} />
          )}
        </div>
        <div className={classes.inputWrapper}>
          {/* {isRename.flag ? ( */}
          {/* <div> */}
          {/* <div className={classes.titleWrapper}>
            <input
              className={inputClassName}
              autoFocus={true}
              onFocus={handleFocus}
              onBlur={() => {
                rename();
                setIsRename({ ...isRename, flag: false });
                setIsFocused(false);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  rename();
                  setIsRename({ ...isRename, flag: false });
                }
              }}
              value={isRename.value}
              type="text"
              onChange={(e) =>
                setIsRename({ ...isRename, value: e.target.value })
              }
              readOnly={decision?.is_sample || secure_token}
            />
          </div> */}
          <div className={classes.titleWrapper}>
            {isFocused && !isShared ? (
              <div>
                <TextArea
                  ref={textAreaRef}
                  rows="1"
                  cols="25"
                  style={{
                    borderColor: ids.includes(optionItem?.id)
                      ? "red"
                      : "#71aaca",
                    zIndex: 1,
                    height: 54,
                    resize: 'none'
                  }}
                  className={inputClassName}
                  autoFocus={true}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  value={isRename.value}
                  type="text"
                  onChange={handleInputChange}
                ></TextArea>
                {ids.includes(optionItem?.id) && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    Option cannot be greater than 255 characters.
                  </span>
                )}
              </div>
            ) : (
              <div
                className={cc(classes.optionContentWrapper, { [classes.active]: !isRename.value })}
                onClick={handleFocus}
              >
                <Tooltip title={isRename.value}>
                  <span className={classes.readOnlyContent}>
                    {isRename.value}
                  </span>
                </Tooltip>

                {ids.includes(optionItem?.id) && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    Option cannot be greater than 255 characters.
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="d-flex flex-column justify-content-center">
            {/* {isFinal && (
              <img src={require("./Cup.png")} alt="" title="Winning Option" />
            )} */}
            {/* {option?.shortlist && <ShortlistIcon title="Shortlisted Option" />} */}
          </div>
          {/* </div> */}
          {/* ) : (
            <div onClick={() => {
              setIsRename({ ...isRename, flag: !isRename.flag });
            }}>
              <div className={classes.titleWrapper}>
                <div
                  className={cc(classes.title, {
                    [classes.winner]: option?.highlighted || isFinal,
                  })}
                >
                  {option?.name || (
                    <span className={classes.placeholder}>
                      Type option name...
                    </span>
                  )}
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center">
                {isFinal && (
                  <img
                    src={require("./Cup.png")}
                    alt=""
                    title="Winning Option"
                  />
                )}
                {option?.shortlist && (
                  <ShortlistIcon title="Shortlisted Option" />
                )}
              </div>
            </div>
          )} */}
        </div>
        <div className={classes.flag}>
          <AntdTooltip
            title={
              +score_criteria_percentage < 70
                ? tooltip.red.title
                : +score_criteria_percentage > 89
                  ? tooltip.green.title
                  : tooltip.yellow.title
            }
            color={
              +score_criteria_percentage < 70
                ? tooltip.red.color
                : +score_criteria_percentage > 89
                  ? tooltip.green.color
                  : tooltip.yellow.color
            }
          >
            {score_criteria_percentage < 70 ? (
              <FlagRed />
            ) : score_criteria_percentage > 89 ? (
              <FlagGreen />
            ) : (
              <FlagYellow />
            )}
          </AntdTooltip>
        </div>
        {columnsToShow?.adjustedScore && (
          <div className={classes.chart}>
            <Space wrap>
              <div>
                <Progress
                  strokeColor={{
                    "0%": "#F72A0F",
                    "20%": "#F0A720",
                    "50%": "#7ED287",
                    "100%": "#87d068",
                  }}
                  type="dashboard"
                  percent={
                    !isShortList
                      ? Math.ceil(second_stage_adjusted_score)
                      : Math.ceil(adjusted_score)
                  }
                  width={46}
                  gapDegree={130}
                  strokeWidth={10}
                  format={(percent) => `${percent}%`}
                />
              </div>
            </Space>
          </div>
        )}
        <div>
          {isView?.shortlist ? (
            <>
              {option?.finalist ? (
                <GoldenFinalist
                  onClick={() => {
                    !decision?.is_sample &&
                      !secure_token &&
                      !isShared &&
                      dispatch(
                        RemoveFromFinalist(
                          { options: option?.id?.toString() },
                          decisionId
                        )
                      );
                  }}
                  className="cursor-pointer"
                />
              ) : (
                <Finalist
                  onClick={() => {
                    !decision?.is_sample &&
                      !secure_token &&
                      !isShared &&
                      dispatch(
                        AddToFinalist(
                          { options: option?.id?.toString() },
                          decisionId
                        )
                      );
                  }}
                  className="cursor-pointer"
                />
              )}
            </>
          ) : (
            <>
              {option?.shortlist ? (
                <PPDARK
                  className={cc({ "cursor-pointer": !isShared })}
                  style={isShared ? { marginRight: 12 } : {}}
                  onClick={() => {
                    !decision?.is_sample &&
                      !secure_token &&
                      !isShared &&
                      dispatch(
                        RemoveFromShortlist(
                          { options: option?.id?.toString() },
                          decisionId
                        )
                      );
                  }}
                />
              ) : (
                <PPLIGHT
                  className={cc({ "cursor-pointer": !isShared })}
                  style={isShared ? { marginRight: 12 } : {}}
                  onClick={() => {
                    !decision?.is_sample &&
                      !secure_token &&
                      !isShared &&
                      dispatch(
                        AddToShortlist(
                          { options: option?.id?.toString() },
                          decisionId
                        )
                      );
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>

      <div className={classes.wrapper}>
        <div>
          {!decision?.is_sample && !secure_token && !isShared && (
            <Dropdown
              overlayClassName="option_dropdown"
              menu={{ items: items?.filter((item) => item?.isShow) }}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <EllipsisOutlined className={classes.popover} rotate={90} />
              </a>
            </Dropdown>
          )}
        </div>
      </div>
      {isShowDeleteModal && (
        <div className={classes.deleteModalContainer}>
          <DeleteModal
            setIsShowDeleteModal={setIsShowDeleteModal}
            value={"option"}
            item={option}
            decisionId={decisionId}
          />
        </div>
      )}
      {isOpenUpload && (
        <UploadModal
          setOption={setOption}
          setIsUpload={setIsUpload}
          option={option}
          decisionId={decisionId}
        />
      )}
      {isShowWinner && (
        <WinnerModal
          decision={userDecision}
          onClose={() => setIsShowWinner(false)}
        />
      )}
    </div>
  );
};

export { Option };
